import { NgModule } from '@angular/core';
import { WelcomeHomeComponent } from './layouts/public-layout/welcome-home/welcome-home.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IrisBaseModule } from '@iris/iris-base';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { IrisLoginModule } from '@iris/iris-login';
import { IrisOtpModule } from '@iris/iris-otp';

@NgModule({
  declarations: [
    WelcomeHomeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    ScrollingModule,
    IrisBaseModule,
    TranslateModule,
    NgxMaskModule.forRoot(),
    IrisLoginModule,
    IrisOtpModule,
  ],
  exports: [
    WelcomeHomeComponent,
    ScrollingModule,
    IrisBaseModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IrisLoginModule,
    IrisOtpModule,
  ],
})
export class BaseModule {}
