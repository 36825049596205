import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdVerificationConfiguration, IdVerificationConstants } from './idVerification.types';

@Injectable()
export class IdVerificationApiKeyInterceptor implements HttpInterceptor {
  constructor(
    @Inject(IdVerificationConstants.CONFIGURATION)
    private idVerificationConfiguration: IdVerificationConfiguration,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (req.url.startsWith(this.idVerificationConfiguration.identityVerificationApi)) {
      req = req.clone({
        setHeaders: {
          'x-api-key': this.idVerificationConfiguration.idVerificationApiKey
        }
      })
    }

    return next.handle(req);
  }
}
