export const environment = {
  production: true,
  accountApi: 'https://api.dev.irsbnk.co/empresas/account',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas/login',
  otpApi: 'https://api.dev.irsbnk.co/empresas/otp',
  identityVerificationApi: 'https://api.dev.irsbnk.co/identity-verification/identityVerification',
  idVerificationApiKey: '0zrdYKUYlFs9uDilkZya55Y36JtmkyIoHKiJ5RYYDxmYvSXFsuSxYth0VsiTAKMn',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket',
  staticApiKey: 'sbzctHBO6Z8u5AtmnKlRb8lTkUmSzwYU6JuW1Nwg',
  sessionConfiguration: {
    domain: 'empresas.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  roleApi: 'https://api.dev.irsbnk.co/empresas/roles',
};
