import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService, SessionTabService } from '@iris/iris-authentication';
import { ResetCacheService } from 'src/lib/services/base/reset-cache.service';
import { SpinnerComponent } from '@iris/iris-base';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  spinnerComponent = SpinnerComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private resetCacheService: ResetCacheService,
    private sessionTabService: SessionTabService,
  ) {}

  ngOnInit() {
    if (
      this.activatedRoute.snapshot.queryParams.concurrentsession === undefined &&
      !this.sessionTabService.hasActiveTab()
    ) {
      this.sessionService.signOut();
      this.resetCacheService.resetCache();
    }
  }
}
