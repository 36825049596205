import { Injectable } from '@angular/core';
// import { BankService } from '../bank/bank.service';
// import { DepositAccountService } from '../depositAccount/depositAccount.service';
// import { InscribedAccountsService } from '../inscribedAccounts/inscribed-accounts.service';
// import { UserService } from '../user/user.service';
// import { TagService } from '../tag/tag.service';
// import { AccountService } from '@iris/iris-login';

@Injectable({
  providedIn: 'root',
})
// TODO
export class ResetCacheService {
  constructor(
    // private accountService: AccountService,
    // private bankService: BankService,
    // private depositAccountService: DepositAccountService,
    // private userService: UserService,
    // private inscribedAccountsService: InscribedAccountsService,
    // private tagService: TagService
  ) {}

  resetCache(): void {
    // this.accountService.resetCache();
    // this.bankService.resetCache();
    // this.depositAccountService.resetCache();
    // this.userService.resetCache();
    // this.inscribedAccountsService.resetCache();
    // this.tagService.resetCache();
  }
}
