import { Component, OnInit } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { SessionTabService } from '@iris/iris-authentication';
import {
  NetworkErrorType,
  NetworkService,
  SessionPreferencesService,
} from '@iris/iris-base';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-iris-login',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private networkService: NetworkService,
    private sessionPreferencesService: SessionPreferencesService,
    private sessionTabService: SessionTabService
  ) {}

  ngOnInit() {
    if (!this.sessionTabService.hasActiveTab()) {
      this.sessionPreferencesService.clear();
      this.router.events
      .pipe(filter((e): e is NavigationError => e instanceof NavigationError))
      .subscribe(e => {
        console.error(e);
        this.networkService.networkError.next(NetworkErrorType.NoConnection);
      });
    }
  }
}
